import { default as consort_45differenceYfcqQloKzyMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/consort-difference.vue?macro=true";
import { default as indexCHBUN20erWMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/category/[category]/index.vue?macro=true";
import { default as indexu4QW23SHikMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/index.vue?macro=true";
import { default as indexkMgiEEXu2fMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/events/[event]/index.vue?macro=true";
import { default as indexNX1a92ebc3Meta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/events/index.vue?macro=true";
import { default as our_45process8BKZvLQiZMMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/our-process.vue?macro=true";
import { default as our_45storyF1uK4Sav4iMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/our-story.vue?macro=true";
import { default as our_45teamKsdvdmpeqrMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/our-team.vue?macro=true";
import { default as our_45warrantyoXlspudev0Meta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/our-warranty.vue?macro=true";
import { default as why_45consort9UwF2ey52bMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/why-consort.vue?macro=true";
import { default as callbackKf2b2lTA7jMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/callback.vue?macro=true";
import { default as _91_46_46_46slug_93hjyjJoXAqRMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexHpxoCFA1SyMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as career_45opportunitiesDcSXHJrwIXMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as contact_45usIYswXQZJxzMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/contact-us/contact-us.vue?macro=true";
import { default as for_45realtorsaJUS8WeNRyMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/contact-us/for-realtors.vue?macro=true";
import { default as our_45locationsMYNtVO6S1VMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as request_45an_45appointmentEEtqGZWvqoMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/contact-us/request-an-appointment.vue?macro=true";
import { default as join_45vip_45listBxKEmt7Ng2Meta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/current-offers/join-vip-list.vue?macro=true";
import { default as why_45buy_45nowgNLUE5Tj9MMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/current-offers/why-buy-now.vue?macro=true";
import { default as indexcBPUlU5lshMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/display-homes/[model]/index.vue?macro=true";
import { default as indexKOT9xWc1vnMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/gallery/heritage-gallery/index.vue?macro=true";
import { default as indexvdrcvv9AUKMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/gallery/hometown-gallery/index.vue?macro=true";
import { default as indext5fmnjU3niMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/gallery/photos/index.vue?macro=true";
import { default as index15K91xuieVMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/gallery/product-gallery/index.vue?macro=true";
import { default as indexBOPMMQYPxOMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/gallery/video/index.vue?macro=true";
import { default as indexwNX2pbMZHzMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/gallery/virtual-tours/index.vue?macro=true";
import { default as indexWMLRxhoJM2Meta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/index.vue?macro=true";
import { default as interactive_45home_45designCEkWT03moFMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/interactive-home-design.vue?macro=true";
import { default as my_45favorites2oOSyuafpOMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as indexINDhfsRxMeMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/new-homes/homes/[home]/index.vue?macro=true";
import { default as indexP0Wh1rpbkJMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/new-homes/plans/[plan]/index.vue?macro=true";
import { default as indexMQdlkgE5ZSMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/new-neighborhoods/[location]/[community]/index.vue?macro=true";
import { default as build_45on_45your_45lotQh8T6otpxVMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/our-homes/build-on-your-lot.vue?macro=true";
import { default as design_45processuMsxUsj0vyMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/our-homes/design-process.vue?macro=true";
import { default as financing1y2DfufSqLMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/our-homes/financing.vue?macro=true";
import { default as indexWcItDhj8eDMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as privacy_45policySusS6f1jybMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46slug_93n2N8ocUdXjMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/promotions/[...slug].vue?macro=true";
import { default as indexH0mJRhLrPwMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/promotions/index.vue?macro=true";
import { default as sandboxgHU8cxXjdXMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as self_45guided_45home_45toursnF1IBWnHuiMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/self-guided-home-tours.vue?macro=true";
import { default as _91testimonial_93VuF9ng453bMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/testimonials/[testimonial].vue?macro=true";
import { default as where_45we_45build01K6XH0h3GMeta } from "/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/where-we-build.vue?macro=true";
export default [
  {
    name: "about-consort-difference",
    path: "/about/consort-difference",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/consort-difference.vue")
  },
  {
    name: "about-consort-homes-reviews-and-testimonials-category-category",
    path: "/about/consort-homes-reviews-and-testimonials/category/:category()",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/category/[category]/index.vue")
  },
  {
    name: "about-consort-homes-reviews-and-testimonials",
    path: "/about/consort-homes-reviews-and-testimonials",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/index.vue")
  },
  {
    name: "about-events-event",
    path: "/about/events/:event()",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/events/[event]/index.vue")
  },
  {
    name: "about-events",
    path: "/about/events",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/events/index.vue")
  },
  {
    name: "about-our-process",
    path: "/about/our-process",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/our-process.vue")
  },
  {
    name: "about-our-story",
    path: "/about/our-story",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/our-story.vue")
  },
  {
    name: "about-our-team",
    path: "/about/our-team",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/our-team.vue")
  },
  {
    name: "about-our-warranty",
    path: "/about/our-warranty",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/our-warranty.vue")
  },
  {
    name: "about-why-consort",
    path: "/about/why-consort",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/about/why-consort.vue")
  },
  {
    name: "callback",
    path: "/callback",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/callback.vue")
  },
  {
    name: "campaigns-slug",
    path: "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93hjyjJoXAqRMeta || {},
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/campaigns/[...slug].vue")
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/campaigns/index.vue")
  },
  {
    name: "contact-us-career-opportunities",
    path: "/contact-us/career-opportunities",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/contact-us/career-opportunities.vue")
  },
  {
    name: "contact-us-contact-us",
    path: "/contact-us/contact-us",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/contact-us/contact-us.vue")
  },
  {
    name: "contact-us-for-realtors",
    path: "/contact-us/for-realtors",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/contact-us/for-realtors.vue")
  },
  {
    name: "contact-us-our-locations",
    path: "/contact-us/our-locations",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/contact-us/our-locations.vue")
  },
  {
    name: "contact-us-request-an-appointment",
    path: "/contact-us/request-an-appointment",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/contact-us/request-an-appointment.vue")
  },
  {
    name: "current-offers-join-vip-list",
    path: "/current-offers/join-vip-list",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/current-offers/join-vip-list.vue")
  },
  {
    name: "current-offers-why-buy-now",
    path: "/current-offers/why-buy-now",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/current-offers/why-buy-now.vue")
  },
  {
    name: "display-homes-model",
    path: "/display-homes/:model()",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/display-homes/[model]/index.vue")
  },
  {
    name: "gallery-heritage-gallery",
    path: "/gallery/heritage-gallery",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/gallery/heritage-gallery/index.vue")
  },
  {
    name: "gallery-hometown-gallery",
    path: "/gallery/hometown-gallery",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/gallery/hometown-gallery/index.vue")
  },
  {
    name: "gallery-photos",
    path: "/gallery/photos",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/gallery/photos/index.vue")
  },
  {
    name: "gallery-product-gallery",
    path: "/gallery/product-gallery",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/gallery/product-gallery/index.vue")
  },
  {
    name: "gallery-video",
    path: "/gallery/video",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/gallery/video/index.vue")
  },
  {
    name: "gallery-virtual-tours",
    path: "/gallery/virtual-tours",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/gallery/virtual-tours/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/index.vue")
  },
  {
    name: "interactive-home-design",
    path: "/interactive-home-design",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/interactive-home-design.vue")
  },
  {
    name: "my-favorites",
    path: "/my-favorites",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/my-favorites.vue")
  },
  {
    name: "new-homes-homes-home",
    path: "/new-homes/homes/:home()",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/new-homes/homes/[home]/index.vue")
  },
  {
    name: "new-homes-plans-plan",
    path: "/new-homes/plans/:plan()",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/new-homes/plans/[plan]/index.vue")
  },
  {
    name: "new-neighborhoods-location-community",
    path: "/new-neighborhoods/:location()/:community()",
    meta: indexMQdlkgE5ZSMeta || {},
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/new-neighborhoods/[location]/[community]/index.vue")
  },
  {
    name: "our-homes-build-on-your-lot",
    path: "/our-homes/build-on-your-lot",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/our-homes/build-on-your-lot.vue")
  },
  {
    name: "our-homes-design-process",
    path: "/our-homes/design-process",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/our-homes/design-process.vue")
  },
  {
    name: "our-homes-financing",
    path: "/our-homes/financing",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/our-homes/financing.vue")
  },
  {
    name: "our-homes",
    path: "/our-homes",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/our-homes/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/privacy-policy.vue")
  },
  {
    name: "promotions-slug",
    path: "/promotions/:slug(.*)*",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/promotions/[...slug].vue")
  },
  {
    name: "promotions",
    path: "/promotions",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/promotions/index.vue")
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/sandbox.vue")
  },
  {
    name: "self-guided-home-tours",
    path: "/self-guided-home-tours",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/self-guided-home-tours.vue")
  },
  {
    name: "testimonials-testimonial",
    path: "/testimonials/:testimonial()",
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/testimonials/[testimonial].vue")
  },
  {
    name: "where-we-build",
    path: "/where-we-build",
    meta: where_45we_45build01K6XH0h3GMeta || {},
    alias: ["/display-homes/","/our-homes/quick-move-in-homes/"],
    component: () => import("/codebuild/output/src223086960/src/consort-headless/nuxtapp/pages/where-we-build.vue")
  }
]